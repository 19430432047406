<template>
  <section class="h-screen lg:h-[150vh] challenges-bg" id="challenges">
      <div class="flex flex-col justify-center gap-y-6 items-center mx-4 pl-3 pt-16 lg:pt-24 xl:pt-40 xl:mt-10">
        <div v-motion-pop-visible>
          <img class="h-full w-full max-w-[800px] max-h-[70px]" src="../assets/challenges-title.png" alt="chalenges">
        </div>
        <div v-motion-pop-visible>
          <img src="../assets/comingsoon-text.png" class="px-14 lg:mx-0 w-full h-full max-w-[488px] max-h-[140px]" alt="comingsoon">
        </div>
      </div>
      <!-- <div class="flex flex-wrap justify-center items-center mt-14 gap-2 md:gap-6">
          <a href="https://roobet.com/game/hacksaw:1067" target="_blank">
              <img v-motion-pop-visible src="../assets/Challenges1.png" class="w-[280px] ml-5 lg:ml-0 lg:w-full" alt="challenge1">
          </a>
          <a href="https://roobet.com/game/pragmatic:vs20fruitparty" target="_blank">
              <img v-motion-pop-visible src="../assets/Challenges2.png" class="w-[280px] ml-5 lg:ml-0 lg:w-full" alt="challenge2">
          </a>
          <a href="https://roobet.com/game/pragmatic:vs20fruitswx" target="_blank">
              <img v-motion-pop-visible src="../assets/Challenges3.png" class="w-[280px] ml-5 lg:ml-0 lg:w-full" alt="challenge3">
          </a>
      </div> -->
      <br><br>
  </section>
</template>

<script>
export default {
  data() {
    return {
      challenges: [],
    };
  },
};
</script>
<style scoped>
.challenges-bg {
  background-image: url('../assets/challenges-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
