<template>
  <section class="h-auto prev-leaderboard-bg">
    <div class="py-14 lg:py-24 px-2">
      <div class="flex flex-row justify-center items-center">
        <img v-motion-pop-visible src="../assets/prev-lead-title.png" class="lg:px-52" alt="prev-leaderboard">
      </div>
      <div v-if="prevloading == true" class="text-center text-white text-2xl md:text-4xl mt-10 font-bold"
        style="font-family: Russo One, sans-serif;">Waiting for Reset...</div>
      <div v-if="prevloading == false" class="hidden md:block">
        <div class="flex justify-center mt-8 mb:mb-24" style="font-family: Russo One, sans-serif;">
          <table class="w-full lg:w-auto border-separate border-spacing-y-6 msm:m-[1rem]">
            <thead v-motion-slide-visible-bottom class="bg-black"
              style="font-family: Stalinist One, system-ui; filter: drop-shadow(0 0 0.5rem black)">
              <tr>
                <th
                  class="px-1 md:px-[25px] lg:pr-24 lg:pl-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-left bg-[#00C2FF] text-transparent bg-clip-text">
                  Ranks
                </th>
                <th
                  class="px-1 md:px-[25px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center bg-[#00C2FF]  text-transparent bg-clip-text">
                  User
                </th>
                <th
                  class="px-1 md:px-[25px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center bg-[#00C2FF] text-transparent bg-clip-text">
                  Amount
                </th>
                <th
                  class="px-1 md:px-[25px] lg:pl-24 lg:pr-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-right bg-[#00C2FF] text-transparent bg-clip-text">
                  Prize
                </th>
              </tr>
            </thead>
            <div class="mt-4"></div>
            <tbody>
              <tr v-motion-slide-visible-bottom v-for="(item, index) in displayedPrevLead" :key="index">
                <td class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[24px] font-thin text-left">
                  <div class="flex flex-row items-center gap-2">
                    <div class="text-white tracking-widest" style="font-family: Stalinist One, system-ui;">
                      {{ getOrdinalSuffix(index + 1) }}
                    </div>
                  </div>
                </td>
                <td
                  class="px-1 md:px-[25px] lg:px-24 py-2 md:py-6 whitespace-nowrap text-[24px] text-white font-black text-center tracking-[0.2rem]"
                  style="font-family: Russo One, sans-serif;">
                  {{ getMaskstring(item.username) }}
                </td>
                
                <td
                  class="px-1 md:px-[25px] lg:px-24 py-2 md:py-6 whitespace-nowrap text-[24px] text-white font-black text-center tracking-[0.2rem]"
                  style="font-family: Russo One, sans-serif;">
                  ${{ item.weightedWagered.toFixed(2) }}
                </td>

                <td
                  class="px-1 md:px-[25px] lg:px-8 py-2 md:py-6 whitespace-nowrap text-[25px] text-[#00FFD1] font-black text-right tracking-[0.2rem]">
                  ${{ getRoobetRewardValue(index) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <!-- mobile view here -->
      <div v-if="prevloading == false" class="block md:hidden">
        <div class="flex justify-center mt-8 mb:mb-24 text-xl bg-black bg-opacity-70 msm:m-[1rem]"
          style="font-family: Inter, sans-serif;">
          <table class="w-full lg:w-auto msm:m-[1rem] sm:m-[1rem]">
            <thead v-motion-slide-visible-bottom>

              <tr>
                <th class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-left">
                  Ranks
                </th>
                <th
                  class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                  User
                </th>
                <th
                  class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                  Amount
                </th>
                <th class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-right">
                  Prize
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-motion-slide-visible-bottom v-for="(item, index) in displayedPrevLeadMobile" :key="index"
                style="font-family: Inter, sans-serif;">
                <td
                  class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[16px] text-white font-bold text-left msm:font-semibold msm:py-6 msm:text-[18px]">
                  {{ getOrdinalSuffix(index + 1) }}
                </td>
                <td
                  class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[16px] text-white font-normal text-center msm:font-semibold msm:py-6 msm:text-[18px]">
                  {{ getMaskstring(item.username) }}
                </td>
                <td
                  class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[16px] text-white font-normal text-center msm:font-semibold msm:py-6 msm:text-[18px]">
                  ${{
                        item.weightedWagered.toFixed(2)
                      }}
                </td>
                <td
                  class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[16px] text-[#00FFD1] font-bold text-right msm:font-semibold msm:py-6 msm:text-[18px] msm:text-[#00FFD1]">
                  ${{
                        getRoobetRewardValue(index)
                      }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>




    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            displayedPrevLead: [],
            roobetPrevLeaderboard: [],
            packdrawPrevLeaderboard: [],
            displayedPrevLeadMobile: [],
            prevloading: true,

            selectedPrevLeaderboard: true,
        }
    },
    methods: {
        initPrevLeaderboard(){
            const baseUrl = process.env.VUE_APP_BASE_API;
            this.prevloading = true
            axios.get(`${baseUrl}/api/roobet/leaderboard?previous=true`, {
              headers: {
                'x-api-key': process.env.VUE_APP_X_API_KEY,
              },
            }).then(rest => {
              this.roobetPrevLeaderboard = rest.data.leaderboard;
              this.displayedPrevLead = this.roobetPrevLeaderboard.slice(0, 5).map((item)=>{
                return item;
              });
              this.displayedPrevLeadMobile = this.roobetPrevLeaderboard.slice(0, 5).map((item)=>{
                return item;
              });
              this.prevloading = false;
            });
        },
        
        getOrdinalSuffix(n) {
            const s = ['th', 'st', 'nd', 'rd'],
                v = n % 100;
            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        },
        getMaskstring(string) {
          if (string) {
            const firstHalf =  Math.ceil(Number(string.length / 2))
            const secondHalf = string.length - firstHalf

            return string.slice(0, firstHalf) + '*'.repeat(secondHalf)
          }

          return string
        },
        getRoobetRewardValue(rank) {
            const prizes = [500, 250, 125, 75, 50];
            if (rank >= 11) {
                return 25;
            }
            return prizes[rank];
        },
        getPackDrawRewardValue(rank) {
            const prizes = [200, 100, 50, 50];
            if (rank >= 4) {
                return 25;
            }
            return prizes[rank];
        },
    },
    mounted(){
         this.initPrevLeaderboard()
    }
}
</script>

<style scoped>
.prev-leaderboard-bg {
  background-image: url('../assets/previous-leaderboard-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
