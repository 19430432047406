<template>
  <!-- Footer start -->
  <div
    class="bg-black w-full flex justify-center items-center md:justify-between lg:px-24 flex-col md:flex-row gap-2 md:gap-8 lg:gap-8 p-8"
  >
    <!-- Overview start -->
    <div class="text-left md:w-[90%] lg:w-[55%]">
      <p
        class="uppercase text-white text-sm"
        style="font-family: Roboto, sans-serif;"
      >
        If you ever feel that gambling is becoming a problem,
      </p>
      <p
        class="uppercase text-white text-sm"
        style="font-family: Roboto, sans-serif;"
      >
        we urge you to seek help and support.
      </p>
      <br />
      <p class="text-white uppercase text-sm" style="font-family: Roboto, sans-serif;">
        Most people gamble for fun and enjoyment. However, some people think of
        gambling as a way to make money, spend more than they can afford, or use
        gambling to distract themselves from everyday problems.
      </p>
      <br />
      <p class="text-white uppercase text-sm" style="font-family: Roboto, sans-serif;">
        To find further advice, help and support visit:
        <a href="https://www.begambleaware.org" class="text-stone-400"
          >https://www.begambleaware.org/</a
        >
      </p>
    </div>
    <!-- Overview end -->

    <!-- Links start -->
    <div>
      <div
        class="text-white flex justify-center items-center lg:gap-20 gap-12 lg:mr-16"
      >
        <!-- <a href="https://discord.com/invite/mbxYr9mPp5" target="_blank">
          <i class="fa-brands fa-discord socialIcon"></i>
        </a>
        <a href="https://www.instagram.com/oneeyedgregg" target="_blank">
          <i class="fa-brands fa-instagram socialIcon"></i>
        </a>

        <a href="https://x.com/MysticBert" target="_blank">
          <i class="fa-brands fa-x-twitter socialIcon"></i>
        </a>
        <a
          href="https://www.youtube.com/channel/UCEh2P0_alzOhhuAFYfFXi_w"
          target="_blank"
        >
          <i class="fa-brands fa-youtube socialIcon"></i>
        </a>
        <a href="https://kick.com/ramblegamble" target="_blank">
          <i class="fa-brands fa-kickstarter-k socialIcon"></i>
        </a> -->
      </div>
      <br/>
      <p
        class="text-white uppercase text-sm"
        style="font-family: Roboto, sans-serif;"
      >
      We do not take responsibility for misunderstood promotions, <br>losses from gambling in casinos or betting on sites which are linked to <br>or promoted on this website. 
      <br><br>Make sure it is legal for you to use each site inside your jurisdiction, and ALWAYS gamble responsibly!
      </p>
      <!-- Links end -->
    </div>
  </div>
  <!-- Footer end -->
</template>

<script>
export default {};
</script>

<style></style>
