<template>
  <div>
    <!-- Main section start -->
    <section class="h-full main-bg overflow-hidden">
      <div
        class="relative flex-col flex flex-nowrap items-center justify-center w-full place-items-end pt-4 md:pt-32 p-8 sm:px-0 xl:p-8 lg:pt-20 xl:pt-0 3xl:pt-28"
      >
        <div
          class="relative flex justify-center items-center mt-8 lg:mt-[8rem] gap-y-16 md:gap-x-36 xl:mt-[18rem] 2xl:mt-[8rem] 3xl:mt-14 flex-col sm:flex-row w-full msm:gap-y-[2rem]"
        >
          <div class="xl:mt-10 sm:mt-32 lg:mt-10 lg:-ml-10 xl:ml-4 2xl:-ml-5 3xl:mt-16">
            <a href="https://roobet.com/?ref=viv" target="_blank">
              <img
                v-motion-slide-visible-left
                class="-rotate-[2.06deg] w-full max-w-[300px] md:max-[420px] lg:max-w-[450px] lg:max-h-[400px] xl:max-w-[500px] xl:max-h-[470px] 2xl:max-w-[590px] 2xl:max-h-[570px]"
                src="../assets/vivstream-logo.png"
                width="590"
                height="570"
                alt="vivstreams"
              />
            </a>
          </div>
          <div
            class="absolute translate-x-8 translate-y-[30%] sm:translate-y-[10%] md:translate-x-[15%] lg:translate-x-[20%] lg:translate-y-0"
          >
            <a href="https://roobet.com/?ref=viv" class="" target="_blank">
              <img
                v-motion-pop-visible
                src="../assets/X-TEXT.png"
                class="w-[250px] max-w-[500px] md:w-[350px] lg:w-[400px] xl:w-[500px] h-auto msm:w-[200px] msm:mt-[30px] msm:mr-[64px]"
                width="450"
                height="600"
                alt="x"
              />
            </a>
          </div>
          <div class="mt-36 sm:mt-12 lg:mt-0 lg:-mr-20 xl:mt-2 2xl:mt-12 flex justify-center lg:justify-end lg:place-content-end">
            <a
              href="https://roobet.com/?ref=viv"
              target="_blank"
            >
              <img
                v-motion-slide-visible-right
                src="../assets/roobet-herosection-text.png"
                class="w-full max-w-[350px] lg:max-w-[400px] xl:max-w-[500px] 2xl:max-w-[600px] h-auto"
                width="600"
                height="150"
                alt="roobet"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        class="mt-48 flex flex-col justify-center items-center pt-24 md:pt-48 pb-20 lg:p-0 gap-5 lg:gap-6 xl:mt-[20rem] 2xl:mt-[28rem] xl:pb-[119px]"
      >
        <div>
          <img v-motion-pop-visible src="../assets/be-part.png" alt="be-part">
        </div>
        <div>
          <a href="https://roobet.com/?ref=viv" target="_blank">
            <img v-motion-pop-visible src="../assets/vivstream-link-text.png" alt="vivstream-link-text">
          </a>
        </div>
        <div>
          <img v-motion-pop-visible src="../assets/lead-reset.png" alt="lead-reset.png">
        </div>
      </div>
    </section>

    <LeaderboardSection />

    <section class="h-auto shadow-inner social-bg" id="socials">
      <div class="flex flex-col justify-center pt-14 md:pt-14 lg:pt-14">
        <div class="flex justify-center">
          <img
            v-motion-pop-visible
            src="../assets/linkstomysocials-text.png"
            class="lg:px-52"
            alt="socials-title"
          />
        </div>
        <div
          class="flex flex-col lg:flex-row justify-center items-center pb-14 lg:gap-24 md:mt-4 lg:mt-8 md:pb-24 lg:pb-14"
        >
          <div
            v-motion-pop-visible
            class="flex px-4 md:px-14 flex-wrap items-center justify-center gap-2 lg:gap-[70px] py-5 mt-5 lg:mt-0 lg:py-11 bg-gradient-to-r from-[#A10000] via-[#0E0E00] to-[#530046] rounded-3xl"
            style="box-shadow: inset 0 0 15px black"
          >
            <a href="https://x.com/poopedahnem" target="_blank">
              <img
                v-motion-slide-visible-bottom
                class="w-10 h-10 lg:h-[50px] lg:w-[50px]"
                src="../assets/twitter.png"
                alt="twitter"
              />
            </a>
            <a href="https://www.youtube.com/@VivStreams" target="_blank">
              <img
                v-motion-slide-visible-bottom
                class="w-10 h-10 lg:h-[50px] lg:w-[50px] grayscale"
                src="../assets/youtube.png"
                alt="youtube"
              />
            </a>
            <a
              href="https://www.instagram.com/vivstreams/"
              target="_blank"
            >
              <img
                v-motion-slide-visible-bottom
                class="w-10 h-10 lg:h-[50px] lg:w-[50px]"
                src="../assets/instagram.png"
                alt="instagram"
              />
            </a>
            <a href="https://kick.com/vivstreams" target="_blank">
              <img
                v-motion-slide-visible-bottom
                class="w-10 h-10 lg:h-[50px] lg:w-[50px]"
                src="../assets/kick.png"
                alt="kick"
              />
            </a>
            <a href="https://discord.com/invite/wXEZRuUnJP" target="_blank">
              <img
                v-motion-slide-visible-bottom
                class="w-10 h-10 lg:h-[50px] lg:w-[50px]"
                src="../assets/discord.png"
                alt="discord"
              />
            </a>
            <a
              href="https://www.tiktok.com/@vivstreams"
              target="_blank"
            >
              <img
                v-motion-slide-visible-bottom
                class="w-10 h-10 lg:h-[50px] lg:w-[50px]"
                src="../assets/tiktok.png"
                alt="tiktok"
              />
            </a>
          </div>
        </div>
      </div>
    </section>

    <ChallengesSection />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import LeaderboardSection from "@/sections/LeaderboardSection.vue";
import ChallengesSection from "@/sections/ChallengesSection.vue";

export default defineComponent({
  components: {
    LeaderboardSection,
    ChallengesSection,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {},
});
</script>

<style scope>
body {
  background-color: #0b0b0c;
}
.main-bg {
  background-image: url("../assets/combined-hero-section bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.social-bg {
  background-image: url("../assets/socials-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}


</style>
